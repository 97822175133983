import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Videos from '../src/Screens/Videos';
import Users from './Screens/Users';
import Notification from './Screens/Notification';
import Transaction from './Screens/Transactions';
import FeedsManagement from './Screens/FeedsManagment';
import axios from 'axios';
import Foreverlogin from './Screens/Foreverlogin';
import PageLayout from './Components/PageLayout';
import ProtectedRoutes from './middleWare/ProtectedRouting/ProtectedRoute';

const App = () => {

  axios.defaults.baseURL = 'https://goldfish-app-hy9e2.ondigitalocean.app/v1'
  axios.defaults.headers.common['Authorization'] = localStorage.getItem('accessT');
  return (
    <Router>

      <Routes>
        <Route path='/login' element={<Foreverlogin />} />

        {/* <Login /> */}
        <Route path='/' element={<Navigate to="/login" />} />

        <Route element={<ProtectedRoutes />}>
          <Route path='/videos' element={<PageLayout> <Videos /></PageLayout>} />
          <Route path='/feeds-management' element={<PageLayout> <FeedsManagement /></PageLayout>} />
          <Route path='/users' element={<PageLayout> <Users /></PageLayout>} />
          {/* <Route path='/notification' element={<PageLayout> <Notification /></PageLayout>} />
          <Route path='/transaction' element={<PageLayout> <Transaction /></PageLayout>} /> */}
        </Route>

        {/* Uncomment the following routes if you implement them later */}
        {/* <Route path='/orders' element={<Orders />} /> */}
        {/* <Route path='/setting' element={<Setting />} /> */}

      </Routes>


    </Router>
  );
};

export default App;
