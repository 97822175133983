import React, { useState, useEffect } from 'react';
import { Table, Avatar, Space, Modal, Button, Input, message, Popconfirm, Tooltip } from 'antd';
import axios from 'axios';
import dummyUser from '../Images/dummyUser.png';
import '../Components/CSS/Users.css';
import edit from '../Images/edit.png';
import deleteBtn from '../Images/delete.png';
import resetPass from '../Images/resetPass.svg';
import FeedModal from './FeedModal';
import DeleteConfimration from './DeleteConfirmation';
import AddUserModal from './AddUserModal';
import ResetPass from '../Components/Subcomponents/ResetPass';


const { Column } = Table;

const Users = () => {
  const [userData, setUserData] = useState([]);
  const [nameFilter, setNameFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isAddDeleteModal, setIsAddDeleteModal] = useState(false);
  const [modalMode, setModalMode] = useState('create'); // State variable to track modal mode
  // const [formData, setFormData] = useState({ title: '', description: '', image: null, video: null }); // Adjusted formData structure
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState('');
  const [Loading, setLoading] = useState('');


  const fetchData = async () => {
    try {
      const response = await axios.get('/admin/all-user');
      setUserData(response?.data?.data?.reverse());
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = () => {
    setLoading(true)
    // Modal.confirm({
    //   title: 'Confirm',
    //   content: 'Are you sure you want to delete this user?',
    //   onOk() {
    //     console.log('Deleting user with id:', id);
    axios.delete(`/user/delete`, {
      data: { firebase_id: selectedUser?.firebase_id }
    }).then(res => {
      setIsAddDeleteModal(false)
      message.success('Deleted Successfully')
      setLoading(false)
      fetchData();
    }).catch(error => {
      setLoading(false)
      console.log({ error })
      setError(getErrorMessage(error.response.data.message?.match(/\((.*?)\)/)) || error.response.data.message)
    })

    // },
    // onCancel() {
    //   console.log('Delete canceled');
    // },
    // });
  };

  const handleAddOpenModal = () => {
    setIsAddModalVisible(true);
    setModalMode('create');
    setError(null)
  };
  const OpenDeleteModal = (record) => {
    setSelectedUser(record)
    setIsAddDeleteModal(true);
  };

  const handleEdit = (user) => {
    setSelectedUser(user)
    setModalMode('edit')
    setIsAddModalVisible(true)
    setError(null)
  }

  const handleCreateOrUpdateNew = (formData, setLoading) => {
    if (modalMode == 'create') {
      axios.post('/user/create', formData)
        .then(response => {
          console.log({ response })
          setLoading(false)
          if (response?.data?.success) {
            message.success('Created Successfully')
            setIsAddModalVisible(false)
            setUserData([response.data.data.user, ...userData])
          }
        })
        .catch(error => {
          setError(getErrorMessage(error.response.data.error?.match(/\((.*?)\)/)))
          setLoading(false)
        });
    }
    else {

      axios.post('/user/update', formData)
        .then(response => {
          console.log({ response })
          setLoading(false)
          if (response?.data?.success) {
            message.success('Updated Successfully')
            setIsAddModalVisible(false)
            let newData = userData?.map(each => {
              if (each?.uid == formData?.uid) {
                return response.data.data.user
              }
              else {
                return each
              }
            })
            setUserData(newData)
          }
        })
        .catch(error => {
          setError(getErrorMessage(error.response.data.error?.match(/\((.*?)\)/)))
          setLoading(false)
        });
    }
  }



  const getErrorMessage = (errorCode) => {
    console.log({ errorCode })
    switch (errorCode?.length > 1 ? errorCode[1] : errorCode) {
      case "auth/email-already-in-use":
      case "account-exists-with-different-credential":
      case "email-already-in-use":
        return "Email already used.";
        break;
      case "auth/wrong-password":
      case "wrong-password":
        return "Wrong email/password combination.";
        break;
      case "auth/user-not-found":
      case "user-not-found":
        return "No user was found with this email.";
        break;
      case "auth/user-disabled":
      case "user-disabled":
        return "The User is disabled.";
        break;
      case "auth/too-many-requests":
      case "operation-not-allowed":
        return "Too many requests to log into this account.";
        break;
      case "auth/operation-not-allowed":
      case "operation-not-allowed":
        return "Server error, please try again later.";
        break;
      case "auth/invalid-email":
      case "invalid-email":
        return "The Email address is invalid.";
        break;
      default:
        return errorCode;
        break;
    }
  }

  const filteredData = userData.filter(user => {
    const nameMatch = user.name.toLowerCase().includes(nameFilter.toLowerCase());
    const emailMatch = user.email.toLowerCase().includes(emailFilter.toLowerCase());
    return nameMatch && emailMatch;
  });

  return (
    <div className='users'>
      <div className='user-controls w-100 d-flex justify-content-between gap-2'>
        <div className='d-flex gap-2'>
          <Input
            placeholder="Search by Name"
            value={nameFilter}
            onChange={e => setNameFilter(e.target.value)}
            className='py-1'
          />
          <Input
            placeholder="Search by Email"
            value={emailFilter}
            onChange={e => setEmailFilter(e.target.value)}
            className='py-1'
          />
          <button className="ms-2 btn bg-pink text-white py-1 px-3" onClick={() => { setNameFilter(''); setEmailFilter(''); }}>Reset</button>
        </div>
        <button className='btn bg-pink ms-auto  text-white py-1 px-3' onClick={handleAddOpenModal}>Add User</button>
      </div>
      <div className='user-table'>
        <Table dataSource={filteredData} rowKey="uid"
          scroll={{
            x: '500px',
          }}
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}

        >
          <Column
            title="#"
            render={(item, record, index) => (<>{index + 1}</>)}
          // dataIndex="uid"
          // key="uid"
          // sorter={(a, b) => a.uid - b.uid}
          />
          <Column
            title="User"
            key="name"
            dataIndex="name"
            render={(text, record) => (
              <Space size="middle">
                <Avatar src={record.imageURL || dummyUser} />
                <div>
                  <p className='mb-0' >{text}</p>
                  <p className='mb-0 text-muted fs-8' >{record.dob}</p>
                </div>
              </Space>
            )}
          />
          <Column
            title="Email"
            dataIndex="email"
            key="email"
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(text, record) => (
              text == "true" ? <div className='rounded-1 ActiveBtn px-3 py-2 w-fitContent'>
                Active
              </div> :
                <div className='rounded-1 PauseBtn px-3 py-2 w-fitContent text-nowrap'>
                  In-Active
                </div>
            )}
          // filters={[
          //   { text: 'Active', value: 'Active' },
          //   { text: 'Inactive', value: 'Inactive' },
          // ]}
          // onFilter={(value, record) => record.status === value}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <div className="d-flex gap-2">
                <Tooltip title="Edit User" color={'E3526E'} key={'#E3526E'}>
                  <img src={edit} height='25px' onClick={() => handleEdit(record)} />                {/* onClick={() => onEdit(record)} */}
                </Tooltip>

                <ResetPass record={record} />

                <Tooltip title="Delete user" color={'E3526E'} key={'#E3526E'}>
                  <img src={deleteBtn} height='25px' onClick={() => OpenDeleteModal(record)} />
                </Tooltip>



              </div>
            )}
          />
        </Table>
      </div>

      <AddUserModal
        visible={isAddModalVisible}
        onCreateOrUpdate={handleCreateOrUpdateNew}
        onCancel={() => setIsAddModalVisible(false)}
        mode={modalMode}
        initialData={selectedUser}
        error={error}
      />
      <DeleteConfimration
        Loading={Loading}
        visible={isAddDeleteModal}
        onDelete={handleDelete}
        onCancel={() => setIsAddDeleteModal(false)}
      />
    </div>

  );
};

export default Users;
