import React, { useState, useEffect } from 'react';
import axios from 'axios';

function VideoPlayer({ videoID }) {
    const [videoURL, setVideoURL] = useState('');

    useEffect(() => {
        const fetchVideoURL = async () => {
            try {
                const response = await axios.get(`/video/get-link?videoID=${videoID}`);
                setVideoURL(response.data.data.mp4Url);
            } catch (error) {
                console.error('Error fetching video URL:', error);
                // Handle error
            }
        };

        fetchVideoURL();

        // Cleanup function
        return () => {
            // Any cleanup code if needed
        };
    }, [videoID]);

    return (
        <div>
            {videoURL ? (
                <video controls width="100%" height="auto">
                    <source src={videoURL} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            ) : (
                <p>Loading video...</p>
            )}
        </div>
    );
}

export default VideoPlayer;
