import React, { useState } from 'react';
import { Menu, Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import logo from '../Images/logo.png';
import young from '../Images/young.jpg';
import admin from '../Images/admin.png';
import Video from '../Images/Video.svg';
import feed from '../Images/feed.svg';
import Users from '../Images/Users.svg';
import logout from '../Images/logout.svg';
import Auth from '../middleWare/Auth/Auth';

const { Sider } = Layout;

const Sidebar = ({ collapsed, toggleCollapsed }) => {
  // const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // const toggleCollapsed = () => {
  //   setCollapsed(!collapsed);
  // };

  const navi = (link, type) => {
    console.log({ link })
    navigate(link, { state: type });
  }

  const items = [
    {
      key: '5',
      label: 'Long Videos',
      icon: <img src={Video} />,
      onClick: () => navi('/videos', { key: 1 }),
    },
    // {
    //   key: '6',
    //   label: 'Short Videos',
    //   icon: <img src={Video} />,
    //   onClick: () => navi('/videos', { key: 2 }),
    // },
    // {
    //   key: '7',
    //   label: 'Exercise Videos',
    //   icon: <img src={Video} />,
    //   onClick: () => navi('/videos', { key: 3 }),
    // },
    {
      key: 'sub1',
      label: 'Feeds Management',
      icon: <img src={feed} />,
      onClick: () => navi('/feeds-management'),
    },
    {
      key: '3',
      icon: <img src={Users} />,
      label: 'Users',
      onClick: () => navi('/users'),
    },
    {
      key: '4',
      icon: <img src={logout} />,
      label: 'Logout',
      onClick: () => { Auth.logout(); navigate('/login'); },
    },
  ];

  return (
    <Sider
      width={300}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        backgroundColor: '#fff',
        boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.1)',
        transition: 'width 0.3s',
        zIndex: 1,
      }}
      collapsible
      // className={`${}`}
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      breakpoint="lg" // Collapse on larger screens (desktop) 
      collapsedWidth={80} // Set a smaller width when collapsed
    >
      <div style={{ margin: '20px', textAlign: 'center', transition: 'all 0.3s' }}>
        <img src={logo} alt="Logo" style={{ width: collapsed ? '40px' : '80%', height: 'auto' }} />
      </div>

      {!collapsed && (
        <div className="admin-profile d-flex gap-3 px-3 py-3 mx-3 my-4">
          <div className="admin-image">
            <img src={young} width="60px" />
          </div>
          <div className="hand-image">
            <img src={admin} width="90px" />
            <p className="admin-text pt-2 ">Forever Young</p>
          </div>
        </div>
      )}

      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={['/videos']}
        className='px-3'
        items={items}
        selectedKeys={[location.pathname]}
      />
    </Sider >
  );
};

export default Sidebar;
