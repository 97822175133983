import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Form, Input, Row, Col, Spin, message } from 'antd';
import BlogPost from './BlogPost';
import FeedModal from './FeedModal'; // Import the FeedModal component
import '../App.css'
import VideoThumbnail from '../Components/VideoThumbnail';
import uploadicon from '../Images/uploadicon.svg'
import DeleteConfimration from './DeleteConfirmation';


const Feed = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [modalMode, setModalMode] = useState('create'); // State variable to track modal mode
    const [formData, setFormData] = useState({ title: '', description: '', image: null, video: null }); // Adjusted formData structure
    const [uploadProgress, setUploadProgress] = useState(0);
    const [editingVideo, setEditingVideo] = useState(null);
    const [error, setError] = useState('');
    const [toBeDeleted, setToBeDeleted] = useState(null);
    const [pageLoading, setPageLoading] = useState(false);
    const [Loading, setLoading] = useState(false);


    useEffect(() => {
        setPageLoading(true)
        fetchBlogPosts();
    }, []);

    const fetchBlogPosts = () => {
        axios.get('/blog/get-all')
            .then(response => {
                setBlogPosts(response.data.data);
                setIsModalVisible(false);
                setPageLoading(false)
            })
            .catch(error => {
                setPageLoading(false)
                console.error('Error fetching blog posts:', error);
            });
    }

    const handleOpenModal = () => {
        setIsModalVisible(true);
        setModalMode('create');
    };
    const OpenDeleteModal = (record) => {
        setToBeDeleted(record)
        setIsDeleteModal(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setFormData({ title: '', description: '', image: null, video: null });
    };

    const showEditModal = (video) => {
        setModalMode('edit');
        setFormData(video);
        setUploadProgress(0)
        setIsModalVisible(true);
    };
    const handleCreateOrUpdateNew = async (formData, isFile) => {
        try {
            // Always append projectId to formData
            formData.append('projectId', 'H4gE7lIZ4U2POc6xn3QzJA');



            if (isFile) {
                // Upload video only for new videos
                const videoUploadResponse = await axios.post(
                    'https://upload.dyntube.com/v1/videos',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJmb3JldmVyeW91bmdhcHAuYnVzaW5lc3NAZ21haWwuY29tIiwianRpIjoiYzQ2NDAwZDMtMzZhNi00MGNmLWIwOTUtZDEwOWYyYTQ2MzEwIiwidXNlcklkIjoiNjU5NzA3MWZmYTY2ODJlOGFhZDJmN2Q3IiwiYWNjb3VudElkIjoiQkt1Rkk1MnNUa0tGMGxlMVM5MEFnIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6Imh0dHBzOi8vZHludHViZS5jb20iLCJhdWQiOiJNYW5hZ2UifQ.uaJIGQ79gvwvWto14ETZpNEQtYjc61eNk_MwDuSugt0',
                        },
                        // Track upload progress
                        onUploadProgress: (progressEvent) => {
                            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(percentage);
                        },
                    }
                );
                const videoUrl = videoUploadResponse.data.videoId;

                // Add video URL to formData
                formData.delete('file');
                formData.append('key', videoUrl);
            }

            if (modalMode === 'create') {
                // Create the video with updated formData
                await axios.post(
                    '/blog/create',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                ).then((res) => {
                    message.success('Created Successfully')
                    return false
                })
            }
            else {
                formData.delete('file');
                formData.delete('media');
                formData.append('id', editingVideo.id)
                await axios.post(
                    `/blog/update`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )

            }
            message.success('Updated Successfully')
            setIsModalVisible(false);
            fetchBlogPosts();
        } catch (error) {
            console.error('Failed to create or update video:', error);
            setError(error)
            return false
        }
    };


    const handleDelete = async () => {
        setLoading(true)
        const dataToSend = { id: toBeDeleted?.id };
        // Call API to delete blog post
        try {
            await axios.delete(`/blog/delete`, {
                data: dataToSend
            });
            // Remove the deleted blog from the state
            setIsDeleteModal(false);
            setLoading(false);
            message.success('Deleted Successfully')
            fetchBlogPosts();

        } catch (error) {
            setLoading(false);
            console.error('Error deleting blog:', error);
        }
    };

    return (
        <>
            <div className="container px-5">
                <div className="feed">

                    <div className="row pt-5">
                        <h5 className="admin-videos pb-5 ">Videos</h5>

                        <button onClick={handleOpenModal} className="btn btn-outine video-upload-button bg-white rounded-3">
                            <div className="d-flex gap-2 justify-content-center align-items-center">
                                <img src={uploadicon} height='24px' />
                                <p className="mb-0">Add Feed</p>
                            </div>
                        </button>
                    </div>

                    <div className='pt-3'>

                        <Spin spinning={pageLoading} >
                            <div className="row pt-3">
                                {blogPosts.map(blog => (
                                    <VideoThumbnail record={blog} onEdit={showEditModal} onDelete={OpenDeleteModal} />
                                ))}
                            </div>
                        </Spin>
                    </div>
                </div>

            </div>

            <FeedModal
                visible={isModalVisible}
                onCreateOrUpdate={handleCreateOrUpdateNew}
                onCancel={handleCancel}
                mode={modalMode}
                initialData={formData}
                uploadProgress={uploadProgress} // Pass uploadProgress state
            />
            <DeleteConfimration
                Loading={Loading}
                visible={isDeleteModal}
                onDelete={handleDelete}
                onCancel={() => setIsDeleteModal(false)}
            />
        </>
    );
}

export default Feed;
