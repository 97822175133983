import Layout from 'antd/es/layout/layout';
import Sidebar from '../Components/Sidebar';
import { useState } from 'react';

const PageLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <Layout>
            <Sidebar collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            <Layout style={{ marginLeft: collapsed ? 80 : 300, transition: 'margin-left 0.3s' }}>
                {children}
            </Layout>
        </Layout>
    );
}

export default PageLayout;
