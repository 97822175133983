import { Popconfirm, Tooltip, message } from "antd";
import { useState } from "react";
import resetPass from '../../Images/resetPass.svg'
import axios from "axios";

const ResetPass = ({ record }) => {


    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = (record) => {
        setConfirmLoading(true);
        axios.get(`/user/password-reset?email=${record.email}`)
            .then(response => {
                if (response?.data?.success) {
                    message.success('Password Request has been Sent to email')
                    setOpen(false);
                    setConfirmLoading(false);
                }
            })
            .catch(error => {
                message.error(getErrorMessage(error.response.data.error?.match(/\((.*?)\)/)[1]))
            });
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    const getErrorMessage = (errorCode) => {
        switch (errorCode) {
            case "auth/email-already-in-use":
            case "account-exists-with-different-credential":
            case "email-already-in-use":
                return "Email already used.";
                break;
            case "auth/wrong-password":
            case "wrong-password":
                return "Wrong email/password combination.";
                break;
            case "auth/user-not-found":
            case "user-not-found":
                return "No user was found with this email.";
                break;
            case "auth/user-disabled":
            case "user-disabled":
                return "The User is disabled.";
                break;
            case "auth/too-many-requests":
            case "operation-not-allowed":
                return "Too many requests to log into this account.";
                break;
            case "auth/operation-not-allowed":
            case "operation-not-allowed":
                return "Server error, please try again later.";
                break;
            case "auth/invalid-email":
            case "invalid-email":
                return "The Email address is invalid.";
                break;
            default:
                return "Registration failed. Please try again.";
                break;
        }
    }

    return (
        <>
            <Popconfirm
                title="Reset Password"
                description="Reset Password request will be sent to email"
                open={open}
                onConfirm={() => handleOk(record)}
                okButtonProps={{
                    loading: confirmLoading,
                }}
                onCancel={handleCancel}
            >
                <Tooltip title="Reset Password" color='E3526E' key={'#E3526E'}>
                    <img src={resetPass} height='25px' onClick={showPopconfirm} />
                </Tooltip>
            </Popconfirm>
        </>
    );
}

export default ResetPass;