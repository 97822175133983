import React, { useState, useEffect } from 'react';
import { message, Spin } from 'antd';
import axios from 'axios';
import VideoModal from './VideoModal';
import { useLocation } from 'react-router-dom/dist/umd/react-router-dom.development';
import uploadicon from '../Images/uploadicon.svg'
import VideoThumbnail from '../Components/VideoThumbnail';
import DeleteConfimration from './DeleteConfirmation';

const Video = () => {

  const [videos, setVideos] = useState({
    long: [],
    short: [],
    exercise: [],
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [editingVideo, setEditingVideo] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteVideoModal, setIsDeleteVideoModal] = useState(false);
  const [modalMode, setModalMode] = useState('create');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    setPageLoading(true)
    fetchData();
  }, []);
  const { state } = useLocation();

  useEffect(() => {

  })

  const fetchData = async () => {
    try {
      const response = await axios.get('/video/get-all');
      const data = response.data.data;
      setPageLoading(false)

      // Separate videos into categories
      setVideos({
        long: data.filter(video => video.type === 'long'),
        short: data.filter(video => video.type === 'short'),
        exercise: data.filter(video => video.type === 'exercise'),
      });
    } catch (error) {
      setPageLoading(false)
      console.error('Failed to fetch video data:', error);
    }
  };

  const handleDelete = async () => {
    setLoading(true)
    const dataToSend = { id: toBeDeleted?.id };
    try {
      await axios.delete(`/video/delete`, {
        data: dataToSend
      });
      message.success('Deleted Successfully')
      fetchData();
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Failed to delete video:', error);
    }
  };

  const showEditModal = (video) => {
    setEditingVideo(video);
    setModalMode('edit');
    setUploadProgress(0)
    setIsModalVisible(true);
  };

  const OpenVideoDeleteModal = (record) => {
    setToBeDeleted(record)
    setIsDeleteVideoModal(true);
  };
  const handleAddNew = () => {
    setEditingVideo(null);
    setModalMode('create');
    setUploadProgress(0)
    setIsModalVisible(true);
  };

  const handleCreateOrUpdate = async (formData, isFile) => {
    try {
      // Always append projectId to formData
      formData.append('projectId', 'H4gE7lIZ4U2POc6xn3QzJA');



      if (isFile) {
        // Upload video only for new videos
        const videoUploadResponse = await axios.post(
          'https://upload.dyntube.com/v1/videos',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJmb3JldmVyeW91bmdhcHAuYnVzaW5lc3NAZ21haWwuY29tIiwianRpIjoiYzQ2NDAwZDMtMzZhNi00MGNmLWIwOTUtZDEwOWYyYTQ2MzEwIiwidXNlcklkIjoiNjU5NzA3MWZmYTY2ODJlOGFhZDJmN2Q3IiwiYWNjb3VudElkIjoiQkt1Rkk1MnNUa0tGMGxlMVM5MEFnIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6Imh0dHBzOi8vZHludHViZS5jb20iLCJhdWQiOiJNYW5hZ2UifQ.uaJIGQ79gvwvWto14ETZpNEQtYjc61eNk_MwDuSugt0',
            },
            // Track upload progress
            onUploadProgress: (progressEvent) => {
              const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setUploadProgress(percentage);
            },
          }
        );
        const videoUrl = videoUploadResponse.data.videoId;

        // Add video URL to formData
        formData.delete('file');
        formData.append('key', videoUrl);
      }

      if (modalMode === 'create') {
        // Create the video with updated formData
        await axios.post(
          '/video/create',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        ).then((res) => {
          message.success('Created Successfully')
          return false
        })
      }
      else {
        formData.delete('file');
        // formData.delete('media');
        formData.append('id', editingVideo.id)
        await axios.post(
          `/video/update`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

      }
      message.success('Updated Successfully')
      setIsModalVisible(false);
      fetchData();
    } catch (error) {
      console.error('Failed to create or update video:', error);
      setError(error)
      return false
    }
  };


  return (
    <>

      <div className="container px-5">
        <div className="row pt-5">
          <h5 className="admin-videos pb-5 ">Videos</h5>
          {/* <Button primary onClick={handleAddNew} style={{ marginBottom: 16, width: 200 }}>
            Add New Video
          </Button> */}
          <button onClick={handleAddNew} className="btn btn-outine video-upload-button bg-white rounded-3">
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <img src={uploadicon} height='24px' />
              <p className="mb-0">Upload Video</p>
            </div>
          </button>
        </div>
        <div className='pt-3'>

          <Spin spinning={pageLoading} >
            <div className="row pt-3 ">

              {(() => {
                switch (state?.key) {
                  case 1:
                    return videos?.long?.map(each => (<VideoThumbnail record={each} onEdit={showEditModal} onDelete={OpenVideoDeleteModal} />))
                  // return <Table dataSource={videos.long} columns={columns} rowKey="id" onRow={(record) => ({
                  //   onClick: () => handleRowClick(record.key),
                  // })} />;
                  case 2:
                    return videos?.short?.map(each => (<VideoThumbnail record={each} onEdit={showEditModal} onDelete={OpenVideoDeleteModal} />))
                  // return <Table dataSource={videos.short} columns={columns} rowKey="id" />;
                  case 3:
                    return videos?.exercise?.map(each => (<VideoThumbnail record={each} onEdit={showEditModal} onDelete={OpenVideoDeleteModal} />))
                  // return <Table dataSource={videos.exercise} columns={columns} rowKey="id" />;
                  default:
                    return null;
                }
              })()}
            </div>
          </Spin>
        </div>
      </div>

      {/* <Tabs defaultActiveKey="1">
        <TabPane tab="Long Videos" key="1">
          <Table dataSource={videos.long} columns={columns} rowKey="id" onRow={(record) => ({
            onClick: () => handleRowClick(record.key),
          })} />
        </TabPane>
        <TabPane tab="Short Videos" key="2">
          <Table dataSource={videos.short} columns={columns} rowKey="id" />
        </TabPane>
        <TabPane tab="Exercise Videos" key="3">
          <Table dataSource={videos.exercise} columns={columns} rowKey="id" />
        </TabPane>
      </Tabs> */}
      <VideoModal
        error={error}
        type={state?.key}
        visible={isModalVisible}
        onCreateOrUpdate={handleCreateOrUpdate}
        onCancel={() => setIsModalVisible(false)}
        mode={modalMode}
        initialVideo={editingVideo}
        uploadProgress={uploadProgress}
      />
      <DeleteConfimration
        Loading={Loading}
        visible={isDeleteVideoModal}
        onDelete={handleDelete}
        onCancel={() => setIsDeleteVideoModal(false)}
      />
    </>
  );
};




export default Video;
