import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Upload, Button, Progress } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import uploadicon from '../Images/uploadicon.svg'


const VideoModal = ({  visible, onCreateOrUpdate, onCancel, mode, initialData, uploadProgress, error }) => {

  const initialValues = {
    title: '',
    desc: '',
    thumbnailFileUpload: null,
    thumbnailFile: null,
    isUploaded: false,
  }


  const [formData, setFormData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log({ visible, onCreateOrUpdate, onCancel, mode, initialData, uploadProgress, error })
    if (visible) {

      if (mode == 'create') {
        setFormData(initialValues)
        setSelectedFile(null)
      }
      else {
        setSelectedFile(null)
        setFormData({ ...initialData, thumbnailFile: initialData?.media, isUploaded: true })
      }
    }
  }, [mode, visible])
  console.log({ initialData, formData })

  // useEffect(() => {
  //   if (mode === 'edit' && initialData) {
  //     setFormData(initialData);
  //   } else {
  //     form.resetFields();
  //     setAvatarFile(null);
  //     setVideoFile(null);
  //   }
  // }, [visible, initialData, mode, form]);

  const onFinish = () => {

    const data = new FormData();

    data.append('title', formData.title);
    data.append('desc', formData.desc);
    // data.append('number', formData.number);
    data.append('media', formData?.thumbnailFileUpload);
    if (selectedFile) {
      data.append('file', selectedFile);
    }

    onCreateOrUpdate(data, !!selectedFile).then(res => {
      setLoading(false)
    })
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleChange = (e) => {
    const { name, value, files, type } = e.target
    if (type === 'file') {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          [name + 'Upload']: file, // Set the thumbnailFile to base64 data
          [name]: reader.result // Set the thumbnailFile to base64 data
        });
      };
      reader.readAsDataURL(file); // Convert the file to base64 data
    }
    else {
      setFormData({
        ...formData,
        [name]: value
      })
    }
  }

  const handleRemoveThumb = () => {
    setFormData({
      ...formData,
      thumbnailFile: null,
      thumbnailFileUpload: ''
    })
  }

  const handleFileChange = (event) => {
    const file = event.target.files ? event.target.files[0] : null; // Get the first file from the selected files
    // Check if the selected file is a video file
    if (file && file.type.startsWith('video/')) {
      setSelectedFile(file);
    } else {
      // Alert the user or handle the error accordingly
      alert('Please select a video file.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    onFinish()
  }

  const removeUploaded = () => {
    setFormData({
      ...formData,
      isUploaded: false,
      thumbnailFile: null
    })
  }

  console.log(formData?.isUploaded)

  return (
    <Modal

      open={visible}
      title={`${mode === 'create' ? 'Upload' : 'Edit'} Feed Video`} //{mode === 'create' ? 'Upload Video' : 'Edit Video'}
      footer={false}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
    >
      <p className="text-muted fw-lighter">Upload the video and fill the information</p>
      <form onSubmit={handleSubmit} >

        <div className="upload-container">
          {!formData?.isUploaded ? <>
            {selectedFile ? (
              <div className="file-container bg-light p-3 rounded">
                <div className="file-info">
                  <p className="mb-0 file-name text-pink" title={selectedFile.name} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{selectedFile.name}</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="mb-0 file-size text-muted">File size: {(selectedFile?.size / 1024 / 1024).toFixed(0)} MB</p>
                      <p className="mb-0 file-type text-muted">Type: Video</p>
                    </div>
                    <button className="btn btn-sm btn-danger" onClick={() => setSelectedFile(null)}>Remove</button>
                  </div>
                </div>
              </div>
            ) : (<>
              <input id='videoFile' type="file" style={{ display: 'none' }} onChange={handleFileChange} accept="video/*" />
              <label htmlFor='videoFile' className="btn btn-outine modal-button video-upload-button bg-white rounded-3 mb-3">
                <div className="d-flex gap-2 justify-content-center align-items-center">
                  <img src={uploadicon} height='24px' />
                  <p className="mb-0 modal-button ">Upload Video</p>
                </div>
              </label>
            </>
            )}
          </> :
            <div className='mb-3'>
              <img src={formData?.thumbnailFile} width='50%' />
              <button type='button' className="btn btn-sm btn-danger" onClick={removeUploaded}>Remove</button>
            </div>
          }
        </div>
        <label htmlFor="title" className="mb-2" >
          Title (required)
        </label>
        <input type='text' className='form-control mb-3 text-feild p-3 rounded-4'
          name='title'
          id='title' value={formData?.title}
          onChange={handleChange}
          required
          placeholder='Enter Video title' />
        <label htmlFor="desc" className="mb-2" >
          Description
        </label>
        <textarea rows={3} className='form-control mb-3 p-3 rounded-3'
          name='desc'
          id='desc' value={formData?.desc}
          onChange={handleChange}
          placeholder='Enter Video description' />
        {!formData?.isUploaded && <>
          <label htmlFor="thumbnailFile" className="mb-2" >
            Thumbnail File
          </label>
          {(!formData?.thumbnailFile) ? <div> <input id='thumbnailFile' name='thumbnailFile' type="file" style={{ display: 'none' }} onChange={handleChange} accept="image/*" />
            <label htmlFor='thumbnailFile' className="btn btn-outine modal-button video-upload-button bg-white rounded-3 mb-3">
              <div className="d-flex gap-2 justify-content-center align-items-center">
                {/* <img src={uploadicon} height='24px' /> */}
                <p className="mb-0 modal-button ">Add Thumbnail</p>
              </div>
            </label></div> :
            <div className="position-relative">
              <img src={formData?.thumbnailFile} width='50%' />
              <button onClick={handleRemoveThumb} className='position-absolute top-0 end-50 border-0 bg-light rounded-circle px-2'>x</button>
            </div>}</>}

        {uploadProgress > 0 && <Progress percent={uploadProgress} />}
        <button disabled={loading} type='submit' className='w-100 rounded-2 bg-pink text-white py-3 border-0' >
          {loading ? (
            // <div className="spinner-border text-light" role="status">
            //     <span className="visually-hidden">Loading...</span>
            // </div>
            <div className="whitespinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          ) : (
            <>Upload Video</>
          )}


        </button>
      </form>
    </Modal >
  );
};

export default VideoModal;
