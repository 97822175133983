import React, { useEffect, useState } from "react";

import { Form } from "antd";
// import FloatInput from "../Components/Subcomponents/FloatInput/Index";
import foreverlogo from "../Images/foreverlogo.png";
import youngimage from "../Images/youngimage.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Auth from "../middleWare/Auth/Auth";
const Foreverlogin = () => {
    const navigate = useNavigate()

    const initialValues = {
        email: '',
        password: '',
    }

    const initialErrors = {
        email: '',
        password: '',
    }

    const [formData, setFormData] = useState(initialValues);
    const [formErrors, setFormErrors] = useState(initialErrors);
    const [IsClassAdded, setIsClassAdded] = useState(false);
    const [apiError, setApiError] = useState('')
    const [Loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const SetErrors = (e) => {
        e.preventDefault()
        const { name, validity } = e.target
        console.log(e.target.validity, e)
        if (validity.valueMissing) {
            setFormErrors({ ...formErrors, [name]: 'This Feild is required' })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        axios.post('/user/login', {
            email: formData.email,
            password: formData.password,
        })
            .then(res => {
                console.log({ res })
                setLoading(false)
                if (res.data.success&&res.data.admin) {
                    setApiError('')
                    localStorage.setItem('accessT', res.data.user.stsTokenManager.accessToken)
                    localStorage.setItem('name', '5103054104894541')
                    axios.defaults.headers.common['Authorization'] = res.data.user.stsTokenManager.accessToken;
                    Auth.login()
                    navigate('/videos', { state: { key: 1 } })
                }
            })
            .catch(err => {
                console.log({ err })
                setLoading(false)
                setApiError(err.response.data.error)
                // console.log(err.response.data.error)
            })
    }

    useEffect(() => {
        if (Auth.isAuthenticated()) {
            navigate('/users')
        }
    }, [])


    return (
        <>
            <div className="bg-white">
                {/* <div className="onboarding-logo px-5 p-5 cursor-pointer" onClick={() => navigate('/')}>
                    <img src={foreverlogo} alt="forever logo" />
                </div> */}
                <div className="container">
                    <div className="row">

                        <div className="col-12 col-lg-6 d-flex align-items-center">
                            <div className="pt-3  enterprise-login_section mx-auto ">
                                <p className="Rubik tc-black fs_34 fw-600 text-center enterprise-login_heading lh_40">Welcome to <br /> <span className="text-center admin-text">   <img src={foreverlogo} alt="forever logo" /></span></p>

                                {apiError &&
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        <strong>Error:</strong> {apiError}
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>
                                }
                                <form onSubmit={handleSubmit} className={IsClassAdded ? 'was-validated' : ''} >
                                    <div className="d-flex flex-column justify-content-center text-center">
                                        <div className="row">
                                            <div className="col-12 py-3 Label-form-section">
                                                <Form.Item name="" className="m-0">
                                                    {/* <FloatInput label="Email" placeholder="Email" val={formData.email} > */}
                                                    <label>Email</label>
                                                    <input type="email" name="email" placeholder="Enter your Email"
                                                        value={formData.email}

                                                        className="input_feild radius-8 form-control Poppins fs_14 w-100 fw-400"
                                                        required
                                                        onInvalid={SetErrors}
                                                        onChange={handleChange} />
                                                    {/* </FloatInput> */}
                                                </Form.Item>
                                                <div className="invalid-feedback text-start">{formErrors.email}</div>
                                            </div>

                                            <div className="col-12 Label-form-section">
                                                <Form.Item name="" className="m-0">
                                                    {/* <FloatInput label="Create a password" placeholder="Create a passwordÏ" val={formData.password} > */}
                                                    <label>Password</label>
                                                    <input type="password" name="password" placeholder="Enter your password"
                                                        value={formData.password}

                                                        className="input_feild radius-8 form-control Poppins fs_14 w-100 fw-400"
                                                        required
                                                        onInvalid={SetErrors}
                                                        onChange={handleChange} />

                                                    {/* </FloatInput> */}
                                                </Form.Item>
                                                <div className="invalid-feedback text-start">{formErrors.password}</div>
                                            </div>
                                            <div className="form-check py-3 text-start mx-3">
                                                <input className="form-check-input" type="checkbox" name='sendEmails' value="" onChange={handleChange} id="flexCheckDefault" />
                                                <label className="form-check-label Rubik fw-400 fs_16 tc-black" htmlFor="flexCheckDefault">
                                                    Remember me
                                                </label>
                                            </div>
                                            <div className="pt-3 px-4 text-center">
                                                <button disabled={Loading} type={!IsClassAdded ? 'button' : 'submit'} className="Rubik bg_alpha_blue tc-snow border-0 py-3 rounded-1 fs_16 px-5 w-100 login-button" onClick={() => setIsClassAdded(true)} >
                                                    {Loading ? (
                                                        // <div className="spinner-border text-light" role="status">
                                                        //     <span className="visually-hidden">Loading...</span>
                                                        // </div>
                                                        <div className="whitespinner">
                                                            <div className="bounce1"></div>
                                                            <div className="bounce2"></div>
                                                            <div className="bounce3"></div>
                                                        </div>
                                                    ) : (
                                                        <>Login</>
                                                    )}</button>
                                                {/* <p className="Rubik tc-black fs_16 fw-400 py-2">Don’t have an account? <span className=" signup-text cursor-pointer Rubik fs_16 fw-600 tc-purple " onClick={() => navigate('/Onboarding')}> Sign up</span></p> */}
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 pt-3 young-image-section">
                            <img src={youngimage} width="100%" alt="forever logo" />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default Foreverlogin;
