import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import play from '../Images/play.png';
import pause from '../Images/Pause.png';
import edit from '../Images/edit.png';
import deleteBtn from '../Images/delete.png';
import placeholderVideo from '../Images/placeholderVideo.svg'

const VideoThumbnail = ({ record, onEdit, onDelete }) => {
    const [showIcons, setShowIcons] = useState(false);
    const [loading, setLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    useEffect(() => {
        setVideoUrl(null)
    }, [record])

    const fetchVideoUrl = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`/video/get-link?videoID=${record?.key || record?.video}`);
            setVideoUrl(response.data.data.mp4Urls[0]);
        } catch (error) {
            console.error('Error fetching video URL:', error);
        }
        setLoading(false);
    };

    const handlePlay = () => {
        fetchVideoUrl();
    };

    const vidRef = useRef(null);

    const handlePause = () => {
        if (vidRef.current.paused) {
            vidRef.current.play();
            setIsVideoPlaying(true);
        } else {
            vidRef.current.pause();
            setIsVideoPlaying(false);
        }
    };

    const handleVideoEnded = () => {
        setVideoUrl(null); // Reset videoUrl to null when the video finishes
    };

    return (
        <div
            className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
        >
            <div className=" video-thumbnail"
                onMouseEnter={() => setShowIcons(true)}
                onMouseLeave={() => setShowIcons(false)}>
                {!videoUrl ? (
                    <div className="video-thumbnail uploaded-image-container">
                        <img src={record?.avatarLink || record?.media || placeholderVideo} className="uploaded-image" alt="Thumbnail" />
                        {showIcons && (
                            <>
                                <div className="backdrop"></div>
                                <div className="icons">
                                    <img src={play} onClick={handlePlay} />
                                    <img src={edit} onClick={() => onEdit(record)} />
                                    <img src={deleteBtn} onClick={() => onDelete(record)} />
                                </div>
                            </>
                        )}
                    </div>
                ) : (
                    <div className="video-thumbnail w-100 h-100">
                        <video
                            src={videoUrl?.url}
                            controls={false}
                            ref={vidRef}
                            id="myVideo"
                            className="w-100 h-100 object-fit-cover"
                            onMouseOver={() => setIsVideoPlaying(true)}
                            onEnded={handleVideoEnded} // Call handleVideoEnded when the video finishes
                            autoPlay // Automatically play the video when loaded
                        />

                        {isVideoPlaying && (
                            <div className="icons w-100 h-100" onMouseOut={() => setIsVideoPlaying(false)} >
                                {!vidRef.current.paused ?
                                    <img src={pause} className="w-auto" style={{ height: '45px' }} alt="Pause" onClick={handlePause} />
                                    :
                                    <>
                                    <img src={play} className="w-auto" style={{ height: '45px' }} alt="Pause" onClick={handlePause} /> 
                                    
                                    <img src={edit} onClick={() => onEdit(record)} />
                                    <img src={deleteBtn} onClick={() => onDelete(record)} />
                                  </>
                                }
                                 
                            </div>
                        )}
                    </div>
                )}
            </div>
            {loading && <div>Loading...</div>}
        </div>
    );
};

export default VideoThumbnail;
