import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Upload, Button, Progress } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import uploadicon from '../Images/uploadicon.svg';



const AddUserModal = ({ visible, onCreateOrUpdate, onCancel, mode, initialData, error }) => {

  const initialValues = {
    name: '',
    email: '',
    password: '',
  }


  const [formData, setFormData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      if (mode == 'create') {
        setFormData(initialValues)
      }
      else {
        setFormData({ ...initialData })
      }
    }
  }, [mode, visible])

  const onFinish = () => {


    onCreateOrUpdate(formData, setLoading)
  };

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    onFinish()
  }

  return (
    <Modal

      open={visible}
      title={`${mode === 'create' ? 'Add' : 'Edit'} User`} //{mode === 'create' ? 'Upload Video' : 'Edit Video'}
      footer={false}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
    >
      <p className="text-muted fw-lighter">Fill the information to add User</p>
      <form onSubmit={handleSubmit} >


        <label htmlFor="name" className="mb-2" >
          Name (required)
        </label>
        <input type='text' className='form-control mb-3 text-feild p-3 rounded-4'
          name='name'
          id='name' value={formData?.name}
          onChange={handleChange}
          required
          placeholder='Enter Name' />

        <label htmlFor="email" className="mb-2" >
          Email
        </label>
        <input type='email' className='form-control mb-3 text-feild p-3 rounded-4'
          name='email'
          id='email' value={formData?.email}
          onChange={handleChange}
          required
          placeholder='Enter Email' />

        {mode == 'create' && <><label htmlFor="password" className="mb-2" >
          Password
        </label>
          <input type='password' className='form-control mb-3 text-feild p-3 rounded-4'
            name='password'
            id='password' value={formData?.password}
            onChange={handleChange}
            required
            placeholder='Enter Password' /></>}

        <p className='text-danger'>{error}</p>
        <button disabled={loading} type='submit' className='w-100 rounded-2 bg-pink text-white py-3 border-0' >
          {loading ? (
            // <div className="spinner-border text-light" role="status">
            //     <span className="visually-hidden">Loading...</span>
            // </div>
            <div className="whitespinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          ) : (
            <>{mode == 'create' ? 'Add User' : 'Edit User'}</>
          )}


        </button>
      </form>
    </Modal >
  );
};

export default AddUserModal;
