import { Modal } from "antd";
import deletle from '../Images/deleteDark.png';

const DeleteConfimration = ({ visible, onDelete, onCancel, Loading }) => {
    return (
        <>
            <Modal
                onCancel={onCancel}
                open={visible}

                footer={false}
                title={false}>
                <div className="text-center delete-modal">
                    <img src={deletle} width="60px" />
                    <p className="delete-text pt-3 ">
                        Are you sure you want
                        to Delete
                    </p>
                    <button disabled={Loading} onClick={onDelete} className="btn-outine video-upload-button delete-button rounded-3">
                        <div className="d-flex gap-2 justify-content-center align-items-center">

                            <p className="mb-0">{Loading ? (
                                // <div className="spinner-border text-light" role="status">
                                //     <span className="visually-hidden">Loading...</span>
                                // </div>
                                <div className="whitespinner">
                                    <div className="bounce1"></div>
                                    <div className="bounce2"></div>
                                    <div className="bounce3"></div>
                                </div>
                            ) : (
                                <>YES, DELETE</>
                            )}</p>
                        </div>
                    </button>
                </div>
            </Modal>

        </>
    );
}

export default DeleteConfimration;
